/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import urls, { options } from 'constants/urls';
import logo from 'assets/img/logo-suvinil-glasu.svg';
import Sidelink from 'components/contexts/Sidelink';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from 'lib/contexts/auth';
import Modal from 'components/structure/Modal/Modal';

import {
  Container,
  Content,
  Menu,
  BorderBottom,
  LogoContainer,
  ContainerModal,
} from './Sidebar.styles';
import { logout } from 'store/auth/auth-slice';
import {
  setOpenSidebarModal,
  setTargetPath,
} from 'store/sidebar/sidebar-slice';
import { setModalResetPassword } from 'store/modals/modals-slice';
// eslint-disable-next-line react/prop-types
const Sidebar = ({ active }) => {
  const location = useLocation();
  const history = useHistory();
  const [isActive, setIsActive] = useState('');
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.auth);
  const { preventRedirect } = useSelector((state) => state.sidebar);
  const [notificationsModal, setNotificationModal] = useState(false);

  const notificationsMock = [
    {
      status: 'done',
      progress: '50%',
      details: 'Cadsatro de Sku concluido',
      timestamp: new Date().getTime(),
    },
    {
      status: 'error',
      progress: '50%',
      details: 'Cadsatro de Sku concluido',
      timestamp: new Date().getTime(),
    },
    {
      status: 'working',
      progress: '50%',
      details: 'Cadsatro de Sku concluido',
      timestamp: new Date().getTime(),
    },
    {
      status: 'error',
      progress: '50%',
      details: 'Cadsatro de Sku concluido',
      timestamp: new Date().getTime(),
    },
    {
      status: 'done',
      progress: '50%',
      details: 'Cadsatro de Sku concluido',
      timestamp: new Date().getTime(),
    },
  ];

  useEffect(() => {
    const path = location.pathname;
    const urlPath = Object.keys(urls.ROUTES).find(
      (url) => urls.ROUTES[url].path === path,
    );
    if (urls.ROUTES[urlPath]) {
      setIsActive(urls.ROUTES[urlPath].name);
    } else {
      let subPath;
      const submenus = Object.keys(urls.ROUTES).filter(
        (route) => urls.ROUTES[route].submenu === true,
      );

      submenus.forEach((item) => {
        const search = Object.keys(urls.ROUTES[item].items).find(
          (url) => urls.ROUTES[item].items[url].path === path,
        );
        if (search) {
          setIsActive(urls.ROUTES[item].items[search].name);
          subPath = urls.ROUTES[item].items[search].name;

          if (!subPath) {
            history.push('/');
          }
        }
      });
    }
  }, [isActive, location, history]);

  const handleSidebarMenu = (name, event, path) => {
    event.preventDefault();
    if (name === 'Sair') {
      dispatch(logout());
      history.push('/login');
    }

    if (preventRedirect) {
      dispatch(setTargetPath(path));
      dispatch(setOpenSidebarModal(true));
    } else if (name === 'Alterar Senha') {
      dispatch(setModalResetPassword({ openModal: true }));
    } else if (name === 'Notificações') {
      setNotificationModal(true);
    } else {
      history.push(path);
    }
  };

  const findPermission = (key) => {
    if (permissions === undefined) {
      return false;
    }

    if (permissions.length === 0) {
      return false;
    }

    const found = permissions.find((permission) => permission.name === key);
    if (found) return found.canAccess;

    return false;
  };

  const showSubmenu = (items) => {
    let response = false;
    Object.keys(items).map((route) => {
      const { key } = items[route];
      const show = findPermission(key);
      if (show === true) {
        response = true;
      }
      return show;
    });

    return response;
  };

  return (
    <Container Col="3">
      <ContainerModal>
        <Modal
          title={'Notificações'}
          isOpen={notificationsModal}
          setIsOpen={setNotificationModal}
          confirmOnClick={() => {}}
          status=""
          containerHeight="100%"
          titleRight
          top={!notificationsMock.length ? '50%' : '200px'}
          left={'600px'}
          isNotificationButton
        >
          <ContainerModal>
            {notificationsMock.length ? (
              <div>Nenhuma notificação recente no momento.</div>
            ) : (
              notificationsMock.map((notification) => {
                return (
                  <ContainerModal>
                    <img src={notification.status} alt="" />
                    <p>{notification.details}</p>
                    <p>{notification.timestamp}</p>
                  </ContainerModal>
                );
              })
            )}
          </ContainerModal>
        </Modal>
      </ContainerModal>
      <LogoContainer>
        <img src={logo} alt="" />
      </LogoContainer>
      <Content>
        <Row key="access">
          <Col>
            {Object.keys(urls.ROUTES).map((route) => {
              const {
                path,
                name,
                label,
                icon,
                submenu,
                items,
                alwaysView,
                key,
              } = urls.ROUTES[route];
              if (findPermission(key) || alwaysView) {
                if (submenu) {
                  if (showSubmenu(items)) {
                    return (
                      <>
                        <Sidelink
                          key={path}
                          nameLink={name}
                          iconLink={icon}
                          items={items}
                          handleSetLink={handleSidebarMenu}
                          isActive={isActive}
                          labelLink={label}
                          handleActive={setIsActive}
                        />
                        <BorderBottom
                          style={{
                            display: name === '' ? 'none' : 'block',
                          }}
                        />
                      </>
                    );
                  }
                  return <></>;
                }
                return (
                  <Link
                    key={path}
                    to={path}
                    onClick={(e) => handleSidebarMenu(name, e, path)}
                  >
                    <Menu
                      active={active === 'crawlers'}
                      className={isActive === name ? 'active' : ''}
                      onClick={() => setIsActive(name)}
                    >
                      <img src={icon} alt="" />
                      {name}
                    </Menu>
                    <BorderBottom
                      style={{
                        display: name === '' ? 'none' : 'block',
                      }}
                    />
                  </Link>
                );
              }
              return null;
            })}
          </Col>
        </Row>
      </Content>

      <Content>
        <Row key="blog">
          <Col>
            {Object.keys(options.ROUTES).map((route) => {
              const { path, name, icon } = options.ROUTES[route];

              return (
                <Link
                  key={path}
                  to={path}
                  onClick={(e) => handleSidebarMenu(name, e, path)}
                >
                  <Menu
                    active={active === 'crawlers'}
                    className={isActive === name ? 'active' : ''}
                    onClick={() => setIsActive(name)}
                  >
                    <img src={icon} alt="" />
                    {name}
                  </Menu>
                  <BorderBottom
                    style={{ display: name === 'Sair' ? 'none' : 'block' }}
                  />
                </Link>
              );
            })}
          </Col>
        </Row>
      </Content>
    </Container>
  );
};

export default Sidebar;
