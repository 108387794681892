import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { FaChevronDown } from 'react-icons/fa';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import SpanError from 'components/structure/SpanError/SpanError';
import {
  selectStyle,
  custom,
  Container,
  Label,
  LabelContainer,
  OptionalStyle,
} from './reactSelect.styles';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown />
    </components.DropdownIndicator>
  );
};

function getArrayOptions(array, data) {
  let options = [];
  array.forEach((item) => {
    const search = data.find((option) => option.value === item);
    options = [...options, search];
  });
  return options;
}

const SelectSearch = ({
  name,
  label,
  placeholder,
  options,
  defaultOption,
  handleChange,
  multi,
  defaultMultiOptions,
  optional,
  disabled,
  clearable,
  title,
  tooltip,
  isError,
  error,
  width,
  minHeight,
  padding,
  paddingControl,
  zIndex,
  ...rest
}) => {
  return (
    <Container
      width={width}
      minHeight={minHeight}
      padding={padding}
      paddingControl={paddingControl}
      zIndex={zIndex}
    >
      <LabelContainer>
        <Label isError={isError}>{label}</Label>

        <TooltipComponent id="tooltipImage" title={title} multiline>
          {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
          {tooltip && <img src={Tooltip} alt="" />}
        </TooltipComponent>
      </LabelContainer>
      {multi ? (
        <Select
          placeholder={placeholder}
          defaultValue={getArrayOptions(defaultMultiOptions, options)}
          value={getArrayOptions(defaultMultiOptions, options)}
          onChange={(value) => handleChange(value.map((item) => item.value))}
          options={options}
          isMulti
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          isDisabled={disabled}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
        />
      ) : (
        <Select
          name={name}
          placeholder={placeholder}
          defaultValue={options.filter(
            (option) => option.value === defaultOption,
          )}
          value={options.filter((option) => option.value === defaultOption)}
          onChange={(event) =>
            event?.value ? handleChange(event.value) : handleChange(event)
          }
          options={options}
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          isClearable={clearable}
          isDisabled={disabled}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
          {...rest}
        />
      )}
      {isError && <SpanError>{error}</SpanError>}
    </Container>
  );
};

// TODO Erro defaultOption
SelectSearch.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  defaultOption: PropTypes.string || PropTypes.number,
  handleChange: PropTypes.func,
  multi: PropTypes.bool,
  defaultMultiOptions: PropTypes.array,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.bool,
  error: PropTypes.string,
  isError: PropTypes.bool,
  width: PropTypes.string,
  minHeight: PropTypes.string,
  padding: PropTypes.string,
  paddingControl: PropTypes.bool,
  zIndex: PropTypes.string,
};

SelectSearch.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  options: [],
  defaultOption: '' || null,
  multi: false,
  handleChange: () => {},
  defaultMultiOptions: [],
  optional: false,
  disabled: false,
  clearable: false,
  title: '',
  tooltip: false,
  error: '',
  isError: false,
  width: '',
  minHeight: '',
  padding: '',
  paddingControl: false,
  zIndex: '',
};

export default SelectSearch;
