import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { FaChevronDown } from 'react-icons/fa';
import Tooltip from 'assets/icons/tooltip-icon.svg';
import TooltipComponent from 'components/structure/Tooltip/Tooltip';
import SpanError from 'components/structure/SpanError/SpanError';
import {
  selectStyle,
  custom,
  Container,
  Label,
  LabelContainer,
  OptionalStyle,
} from './reactSelect.styles';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown />
    </components.DropdownIndicator>
  );
};

const SelectInputOptions = ({
  name,
  label,
  placeholder,
  options,
  value,
  handleChange,
  multi,
  optional,
  disabled,
  title,
  tooltip,
  isError,
  error,
  hookForm,
}) => {
  return (
    <Container isError={isError}>
      <LabelContainer>
        <Label isError={isError}>{label}</Label>

        <TooltipComponent id="tooltipImage" title={title} multiline>
          {optional && <OptionalStyle>(Opcional)</OptionalStyle>}
          {tooltip && <img src={Tooltip} alt="" />}
        </TooltipComponent>
      </LabelContainer>
      {multi ? (
        <Select
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          options={options}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          isMulti
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          isDisabled={disabled}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
        />
      ) : (
        <Select
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          options={options}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          styles={selectStyle}
          components={{ IndicatorSeparator: null, DropdownIndicator }}
          theme={custom}
          isDisabled={disabled}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? '' : 'Item não encontrado'
          }
          loadingMessage={() => 'Carregando...'}
        />
      )}
      {isError && <SpanError>{error}</SpanError>}
      {hookForm && isError && <SpanError>{error.message}</SpanError>}
    </Container>
  );
};

SelectInputOptions.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  handleChange: PropTypes.func,
  multi: PropTypes.bool,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.bool,
  error: PropTypes.string,
  isError: PropTypes.bool,
  hookForm: PropTypes.bool,
};

SelectInputOptions.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  options: [],
  value: {},
  multi: false,
  handleChange: () => {},
  optional: false,
  disabled: false,
  title: '',
  tooltip: false,
  error: '',
  isError: false,
  hookForm: false,
};

export default SelectInputOptions;
